<template>
  <div class="desc">
    <div class="descDoc">
      <div>
        <div><h1>- 散货仓单证 -</h1></div>
        <div>
          <span
            >企业出货货物以金运达货仓、外运仓、梅园仓、嘉里仓,文辉仓,马士基仓,力又仓,裕泰仓,勤辉仓等海运散货入仓所需向海关提交的报关资料文件，我司可代理提供一交点式服务。企业只需提供货物的品名装箱信息、产品价值等相关资料，即可快捷办理好出口相关文件。</span
          >
        </div>
      </div>
    </div>
    <div class="process">
      <div><h1>- 运作流程 -</h1></div>
      <img
        src="../../assets/images/contentImgs/process.svg"
        alt="单证出口流程"
      />
    </div>
    <div class="servelist">
      <div><h1>- 优势 -</h1></div>
      <div>
        <div>
          <img src="../../assets/images/contentImgs/world_lcl.png" alt="货运" />
        </div>
        <div>
          <ul>
            <li>全程一站式服务</li>
            <li>专业化团队，从事报关行业十余年</li>
            <li>质量有保障，提升企业动作效率</li>
            <li>高性价比，专业人员操作，系统化操作，为企业降低成本</li>
            <li>优质服务，一对一的服务专员</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
@import url("../../assets/css/agentcompnt.less");
.descDoc{
  background: #576a74;
  color: white !important;
}
</style>